:root {
  --tg-primary-dark3: #0f0f0f;
  --tg-primary-dark2: #0a0a0a;
  --tg-primary-dark1: #212121;
  /* --tg-primary-main: rgb(55, 114, 255); */
  --tg-primary-main: #f2295b;
  --tg-primary-main2: #e41348;
  --tg-primary-main-100: rgb(255, 246, 248);

  /* --tg-primary-main: #1f1f1f; */
  --tg-primary-light4: rgb(52, 53, 65);
  --tg-primary-light3: rgb(32, 33, 35);
  --tg-primary-light2: #0a0a0a;
  --tg-primary-light1: #0f0f0f;
  --tg-text-main: #212121;
  --tg-text-sub6: #797979;
  --tg-text-sub5: #bcbcbc;
  --tg-text-sub4: #e8e8e8;
  --tg-text-sub3: #f3f3f3;
  --tg-text-sub2: #fbfbfb;
  --tg-text-sub1: #ffffff;

  --tg-body-bg: rgb(255, 255, 255);
  --tg-secondary-color: rgba(33, 37, 41, 0.75);
  --tg-secondary-bg: rgb(233, 236, 239);
  --tg-territory-color: rgba(33, 37, 41, 0.5);
  --tg-emphasis-color: rgb(0, 0, 0);
  --tg-dark-color: rgb(33, 37, 41); /* 배경색 */
  --tg-dark-bg-subtle-color: rgb(206, 212, 218);

  --tg-info-color: #2fbcf2; /* 정보를 나타내는 밝은 파란색 */
  --tg-warning-color: #f2e229; /* 경고를 나타내는 밝은 노란색 */
  --tg-error-color: #f22f2f; /* 오류를 나타내는 진한 빨간색 */
  --tg-blue: #0d6efd;
  --tg-indigo: #6610f2;
  --tg-purple: #6f42c1;
  --tg-pink: #d63384;
  --tg-red: #dc3545;
  --tg-orange: #fd7e14;
  --tg-yellow: #fbff07;
  --tg-yellow-100: #fff9c2;
  --tg-yellow-200: #fff382;
  --tg-green: #198754;
  --tg-green-100: #d4edda;
  --tg-green-200: #c3e6cb;
  --tg-green-300: #b1dfbb;
  --tg-green-400: #a3dab9;
  --tg-green-500: #7bcf9e;
  --tg-green-600: #6cc48a;
  --tg-green-700: #4bb370;
  --tg-teal: #20c997;
  --tg-cyan: #0dcaf0;
  --tg-black: #000;
  --tg-white: #fff;
  --tg-gray: #6c757d;
  --tg-gray-dark: #343a40;
  --tg-gray-50: #f8f9fa;
  --tg-gray-100: #f8f9fa;
  --tg-gray-200: #e9ecef;
  --tg-gray-300: #dee2e6;
  --tg-gray-400: #ced4da;
  --tg-gray-500: #adb5bd;
  --tg-gray-600: #6c757d;
  --tg-gray-700: #495057;
  --tg-gray-800: #343a40;
  --tg-gray-900: #212529;
  --tg-primary-text-emphasis: #052c65;
  --tg-secondary-text-emphasis: #2b2f32;
  --tg-success-text-emphasis: #0a3622;
  --tg-info-text-emphasis: #055160;
  --tg-warning-text-emphasis: #664d03;
  --tg-danger-text-emphasis: #58151c;
  --tg-light-text-emphasis: #495057;
  --tg-dark-text-emphasis: #495057;

  --tg-border-width: 1px;
}

body {
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
}

a {
  text-decoration: none;
  color: var(--tg-text-main);
  cursor: pointer;
}

main {
  background-color: #eff2f6;
}

[data-autoresize] {
  resize: none;
}

.position-sticky {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 100;
}

.position-none {
  position: static;
}

.card {
  --bs-card-spacer-y: 1.25rem;
  --bs-card-spacer-x: 1.25rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: #14191e;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: 1px;
  --bs-card-border-color: rgba(0, 0, 0, 0.09);
  --bs-card-border-radius: 0.4rem;
  --bs-card-box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.04);
  --bs-card-inner-border-radius: calc(0.4rem - 1px);
  --bs-card-cap-padding-y: 1.25rem;
  --bs-card-cap-padding-x: 1.25rem;
  --bs-card-cap-bg: #fff;
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: #fff;
  --bs-card-img-overlay-padding: 1.25rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}

.card-header {
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: #fff;
}

.card-header:first-child {
  border-radius: 0.4rem 0.4rem 0 0;
}

.card-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.logo {
  height: 28px;
}

.swal2-icon {
  border-color: transparent !important;
}
.swal-custom-icon {
  height: 80px;
}

.swal2-confirm {
  background-color: var(--tg-primary-main) !important;
}

.swal2-confirm:focus {
  box-shadow: none !important;
}

.swal2-cancel {
  background-color: transparent !important;
  border: 1px solid var(--tg-gray-600) !important;
  color: var(--tg-primary-dark1) !important;
}

.swal2-title {
  font-size: 1.6rem !important;
}

.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.nav-link {
  color: var(--tg-primary-dark1);
}

.nav-link-secondary .nav-item .nav-link {
  color: var(--bs-gray-800);
}

.nav .nav-item .nav-link {
  background: transparent;
  border: 1px solid transparent;
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
}

.nav-stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  gap: 0.7rem;
}

.nav.nav-divider .nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nav .nav-item .nav-link {
  background: transparent;
  border: 1px solid transparent;
}

.nav-link.bg-light:hover {
  color: var(--tg-primary-main);
  background-color: var(--tg-primary-main-100);
}

.nav-pills .nav-link {
  cursor: pointer;
}

.nav-pills .nav-link {
  border-radius: 0.4rem;
}

.nav.nav-divider .nav-item + .nav-item:before {
  content: "•";
  color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  opacity: 0.8;
}

.avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block;
  -ms-flex-negative: 0 !important;
  flex-shrink: 0 !important;
}

.avatar-lg {
  height: 4rem;
  width: 4rem;
}

.avatar-xs {
  height: 2.1875rem;
  width: 2.1875rem;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.icon-md {
  height: 2.5rem;
  width: 2.5rem;
  font-size: 0.9em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.btn-primary-soft {
  color: var(--tg-primary-main);
  background-color: var(--tg-primary-main-100);
}

.btn-link {
  --bs-btn-font-weight: 500;
  --bs-btn-color: var(--tg-primary-main);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--tg-red);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--tg-primary-main2);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: var(--tg-gray-500);
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  text-decoration: none;
}

.comment-wrap .comment-item-nested {
  padding-left: 2.75rem;
}

.comment-item-nested .comment-item {
  position: relative;
}

.bg-center-cover {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.h-50px {
  height: 50px;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.start-50 {
  left: 50% !important;
}

.top-50 {
  top: 50% !important;
}

.position-absolute {
  position: absolute !important;
}

.topic:hover {
  color: var(--tg-primary-main);
  background-color: var(--tg-primary-main-100);
}

.topic.active {
  color: var(--tg-primary-main);
  background-color: var(--tg-primary-main-100);
}

.nav.small .nav-link:hover {
  color: var(--tg-text-main) !important;
  background-color: var(--tg-yellow) !important;
}

.google-login {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.google-login iframe {
  opacity: 0;
  z-index: 9999;
}

.google-login .google-login-button {
  position: absolute;
  top: 0;
  cursor: pointer;
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.btn-bd-primary {
  --bd-violet-bg: #712cf9;
  --bd-violet-rgb: 112.520718, 44.062154, 249.437846;

  --bs-btn-font-weight: 600;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: var(--bd-violet-bg);
  --bs-btn-border-color: var(--bd-violet-bg);
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #6528e0;
  --bs-btn-hover-border-color: #6528e0;
  --bs-btn-focus-shadow-rgb: var(--bd-violet-rgb);
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #5a23c8;
  --bs-btn-active-border-color: #5a23c8;
}

.bd-mode-toggle {
  z-index: 1500;
}

.bd-mode-toggle .dropdown-menu .active .bi {
  display: block !important;
}

/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  margin-bottom: 4rem;
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  bottom: 3rem;
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 32rem;
}

/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 1.5rem;
  text-align: center;
}
/* rtl:begin:ignore */
.marketing .col-lg-4 p {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
}
/* rtl:end:ignore */

/* Featurettes
------------------------- */

.featurette-divider {
  margin: 5rem 0; /* Space out the Bootstrap <hr> more */
}

/* Thin out the marketing headings */
/* rtl:begin:remove */
.featurette-heading {
  letter-spacing: -0.05rem;
}

.image-gif {
  /* bottom right shadow */
  /* f2295b -> rgb */

  box-shadow: 10px 15px 30px rgba(242, 41, 91, 0.2);
  /* mouse over 이미지 크기 크게 */
  cursor: pointer;
  transition: transform 0.3s ease;
}

.image-gif:hover {
  transform: scale(1.1);
}

/* rtl:end:remove */

/* RESPONSIVE CSS
-------------------------------------------------- */

@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }

  .featurette-heading {
    font-size: 50px;
  }
}

@media (min-width: 62em) {
  .featurette-heading {
    margin-top: 7rem;
  }
}

.ip-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 20px;
  width: 100%;
  /* max-width: 540px; */
  border: 1px solid var(--tg-text-sub5);
  border-radius: 10px;
  background-color: var(--tg-white);
  margin-bottom: 10px;
  box-shadow: 3px 3px 0 0 var(--tg-primary-main-100);
}

.featurette-heading {
  font-size: 36px;
}

.carousel-caption {
  /* bottom: 4rem; */
}

.fc .fc-toolbar-title {
  font-size: 1.1rem;
}

.fc .fc-header-toolbar {
  margin-bottom: 1em !important;
}

.fc table {
  height: 100%;
  font-size: 0.8rem !important;
}
